import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from "react-bootstrap"
import { RiArrowRightSLine } from "react-icons/ri"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ThePage = () => {
  const data = useStaticQuery(graphql`
    query siQuery {
      img0: file(relativePath: { eq: "si_header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img0cn: file(relativePath: { eq: "si_header_cn.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img0en: file(relativePath: { eq: "si_header_en.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img1: file(relativePath: { eq: "si_case.png" }) {
        childImageSharp {
          fluid(maxWidth: 640) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const intl = useIntl()
  const locale = intl.locale
  const tt = key => {
    if (intl.messages[key] === undefined) {
      return key
    } else if (intl.messages[key] === "") {
      return ""
    } else {
      return intl.formatMessage({ id: key })
    }
  }

  return (
    <Layout>
      <SEO title="seo.title.nsp" description="seo.desc.nsp" />
      {locale === "zh-hk" && (
        <Img
          className="rounded mx-auto d-block pb-3 mt-3"
          fluid={data.img0.childImageSharp.fluid}
        />
      )}
      {locale === "zh-cn" && (
        <Img
          className="rounded mx-auto d-block pb-3 mt-3"
          fluid={data.img0cn.childImageSharp.fluid}
        />
      )}
      {locale === "en" && (
        <Img
          className="rounded mx-auto d-block pb-3 mt-3"
          fluid={data.img0en.childImageSharp.fluid}
        />
      )}
      <Container className="si-container">
        <Row className="si-row">
          <Col xl={6} lg={6} md={6} sm={6} xs={12} className="pb-3">
            <div className="si-content">
              {locale !== "en" && (<p>{tt("pages.np.desc1_1")}<Link>{tt("pages.np.desc1_link1")}</Link>{tt("pages.np.desc1_2")}</p>)}
	      {locale !== "en" && (<p>{tt("pages.np.desc2")}</p>)}
              {locale === "en" && (<p>{tt("pages.np.desc1_1")}</p>)}
              {locale === "en" && (<p><Link>{tt("pages.np.desc2_link")}</Link>{tt("pages.np.desc2")}</p>)}
              <p>{tt("pages.np.desc3")}</p>
            </div>
          </Col>
          <Col xl={6} lg={6} md={6} sm={6} xs={12}>
            <Img fluid={data.img1.childImageSharp.fluid} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ThePage
